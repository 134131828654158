import React from "react"
import { Box, VStack, Heading } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import CustomButtonBase from "@/components/CustomButtonBase"


const onSurveyFormClick = href => {
    if (href) {
       window.open(href, "_self")
      }
  }

const CallToAction = () => {
  const { t } = useTranslation()

  return (
    <Box as="section" width="full" backgroundColor="#F5F5F5" textAlign="center" id="JoinUs">
      <VStack mx="auto" maxW="6xl" px="6" py="14" spacing="6">
        {(
          <Heading
            size="lg"
            textAlign="center"
            mb="4"
            fontWeight="600"
            color="#003686"
            fontSize="32px"
          >
          {t("home-page.cta-section.download-today")}
          </Heading>
        )}
        <CustomButtonBase
                text={t(`home-page.cta-section.cta`)}
                width={{ base: "60px", md: "108px" }}
                height="56px"
                mx= "0"
                color="#FFFFFF"
                backgroundColor="#0055B2"
                px={{ base: "2", md: "2" }}
                py={{ base: "2", md: "4" }}
                fontSize={{ base: "xs", md: "16px" }}
                onClick={() => onSurveyFormClick('/userresearch/yara-farmer-expert-signup')}
         />

      </VStack>
    </Box>
  )
}

export default CallToAction

import React from "react"
import { Text } from "@chakra-ui/react"

const CustomButtonBase = ({
  text = "Button",
  onClick,
  ...chakraOverridenProps
}) => (
  <Text
    display="flex"
    alignItems="center"
    justifyContent="center"
    color="white"
    cursor="pointer"
    userSelect="none"
    px="4"
    py="2"
    fontSize="md"
    textAlign="center"
    fontWeight="600"
    borderRadius="0.45rem"
    transition="all 0.2s"
    backgroundColor="ahuaBlue.80"
    _hover={{ backgroundColor: "ahuaBlue.90" }}
    _active={{ backgroundColor: "ahuaBlue.100" }}
    onClick={onClick}
    {...chakraOverridenProps}
  >
    {text}
  </Text>
)

export default CustomButtonBase
